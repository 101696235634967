export const styles = {
  mtOne: { marginTop: 12 },
  mtTwo: { marginTop: 24 },
  mtThree: { marginTop: 36 },
  mbOne: { marginBottom: 12 },
  mrOne: { marginRight: 12 },
  mbTwo: { marginBottom: 24 },
  mbHalf: { marginBottom: 6 },
  pOne: { padding: 12 },
  heading: { fontWeight: 700, },
  primary: { color: "green" },
  flexRow: { display: "flex", "flex-direction": "row" },
  flexColumn: { display: "flex", "flex-direction": "column" },
  justifyCenter: { display: "flex", justifyContent: "center" },
  justifyBetween: { display: "flex", justifyContent: "space-between" },
  itemsCenter: { display: "flex", "align-items": "center" },
  textCenter: { "text-align": "center" },
  inlineFlex: { display: "inline-flex", },
  textLeft: { "text-align": "left" },
};
